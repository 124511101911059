import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout/layout";

import Helmet from "react-helmet";

import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";

import FullSignupForm from "../components/newsletter/full-signup-form";

import BlogIndexListing from "../components/blog/blog-index-listing";

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Notes From a Limestone Ledge</title>
    </Helmet>
    <PageContainer>
      <PageSection background="dark-accent" wrapperType="wrapper">
        <div className="blog-section-title blog-index">
          <Link to="/notes">
            Notes From a Limestone Ledge
          </Link>
        </div>
      </PageSection>
      <PageSection topPadding="md" bottomPadding="lg">
        <ul class="blog-index">
          {data.allMarkdownRemark.edges.map(({ node }, idx) => (
            <BlogIndexListing key={idx} post={node} />
          ))}
        </ul>
      </PageSection>

      <PageSection background="gray" topPadding="md" bottomPadding="md">
        <FullSignupForm />
      </PageSection>

    </PageContainer>
  </Layout>
);

export const query = graphql`
  query NotesQuery {
    allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {fields: {slug: {ne: null}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            summary
          }
          fields {
            slug
          }
        }
      }
    }
  }
`